import { useState } from "react";
import { data } from "../../data";
import "./Menu.css";

const Menu = () => {
  const [filteredFoods, setFilteredFoods] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filterType = (category) => {
    if (category === "all") {
      setFilteredFoods(data);
    } else {
      setFilteredFoods(data.filter((item) => item.category === category));
    }
    setSelectedCategory(category);
  };

  return (
    <div
      id="menu"
      className="max-w-[1640px] mx-auto p-6 bg-black text-white"
    >
      <h1 className="text-center text-4xl font-bold text-red-600 mb-8">
        Our Menu
      </h1>

      <div className="flex flex-wrap justify-center gap-4 mb-8">
        {[
          { label: "All", category: "all" },
          { label: "Sandwiches", category: "sandwich" },
          { label: "Eggs", category: "egg" },
          { label: "Waffle, Cakes, & French Toast", category: "waffle" },
          { label: "Sandwiches & Burritos", category: "burritos" },
          { label: "Healthy Choices", category: "healthy" },
          { label: "Omelets", category: "omellete" },
          { label: "Kids'", category: "kid" },
          { label: "Sides", category: "side" },
          { label: "Starters", category: "starter" },
          { label: "Salads", category: "salad" },
          { label: "Sweets", category: "sweet" },
          { label: "Drinks", category: "drink" },
          { label: "Dinners", category: "dinner" },
          { label: "Soups", category: "soup" },
          { label: "Burgers & Wraps", category: "burger" },
        ].map((button) => (
          <button
            key={button.category}
            onClick={() => filterType(button.category)}
            className={`px-4 py-2 text-sm font-semibold border rounded-lg transition-all ${
              selectedCategory === button.category
                ? "bg-red-600 text-white"
                : "border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
            }`}
          >
            {button.label}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredFoods.map((item, index) => (
          <div
            key={index}
            className="card"
          >
            <h2>{item.name}</h2>
            <p>{item.note || "No additional details"}</p>
            <p className="price">{item.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
