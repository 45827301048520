import serpico from "../assets/serpico.png";
import doordash from "../assets/doordash_logo.png";
import grubhub from "../assets/grubhub-logo.png";
import hiring from "../assets/hiring.jpg";

const images = {
  serpico,
  doordash,
  grubhub,
  hiring,
};

export default images;
