import { getRandomQuote } from "../../data";
import "./Footer.css";

const currentYear = new Date().getFullYear();
const quote = getRandomQuote();

const Footer = () => (
  <div className="app__footer">
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="app__footer-address">
          512 State Fair Blvd, Syracuse, NY 13204
        </p>
        <p className="app__footer-number">
          <a href="tel:3159374556">315-937-4556</a>
        </p>
      </div>

      <div className="app__footer-links_quotes">
        <h1 className="app__footer-headtext">Customer Quotes</h1>
        <p className="app__footer-quote">"{quote}"</p>
      </div>
    </div>

    <div className="app__footer-copyright">
      <p>
        &copy; {currentYear}{" "}
        <a href="https://www.theoldserpico.com/">The Old Serpico</a>. All Rights
        Reserved.
      </p>
      <a
        className="developer"
        href="https://www.josephcarmosino.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Made with 💗 by Joseph Carmosino
      </a>
    </div>
  </div>
);

export default Footer;
