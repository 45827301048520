import { SubHeading } from "../../components";
import './Information.css'

const Information = () => (
  <div className="app__bg app__wrapper section__padding" id="information">
    <div className="info__container">
      <SubHeading title="Information" />
      <h1 className="info__heading">Find Us</h1>
      <p className="info__text">
        <a
          href="https://www.google.com/maps?sca_upv=1&um=1&fb=1&gl=us&geocode=KfMgFKV18dmJMQuhyDovnc4X&daddr=512+State+Fair+Blvd%2C+Syracuse%2C+NY+13204"
          target="_blank"
          rel="noopener noreferrer"
        >
          We are located at 512 State Fair Blvd, Syracuse, NY 13204!
        </a>
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.902583091385!2d-76.1979573845869!3d43.06465437914485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3c764ba97cf%3A0x59c3c2b2f3b3fa4c!2s512%20State%20Fair%20Blvd%2C%20Syracuse%2C%20NY%2013204%2C%20USA!5e0!3m2!1sen!2sus!4v1699999999999!5m2!1sen!2sus"
        className="info__map"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <h2 className="info__hours-heading">Hours</h2>
      <p className="info__hours">
        Monday - Sunday: 5:30 AM - 2:00 PM
      </p>
      <p className="info__note">
        Please note that our diner hours are subject to change. We appreciate
        your understanding and encourage you to check our website or contact
        us for the latest updates on our operating hours.
      </p>
      <button
        className="info__button"
        onClick={() =>
          window.open(
            "https://www.google.com/maps?um=1&fb=1&gl=us&geocode=KfMgFKV18dmJMQuhyDovnc4X&daddr=512+State+Fair+Blvd%2C+Syracuse%2C+NY+13204"
          )
        }
      >
        Directions
      </button>
    </div>
  </div>
);

export default Information;
