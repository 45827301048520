import { SubHeading } from "../../components";
import "./Header.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
  <div className="app__header-wrapper">
    <SubHeading title="Rediscover Timeless Flavors at The Old Serpico Diner" />
    <h1 className="app__header-h1">Welcome to The Old Serpico Diner</h1>
    <p className="app__header-description">
      Step into the heart of nostalgia with a touch of Italian flair at The Old Serpico Diner. 
      As you enter, you'll be greeted not only by the warm, vintage atmosphere but also by 
      the spirit of authentic Italian hospitality. Our menu reflects our heritage, offering a 
      delightful fusion of classic diner favorites and traditional Italian flavors. Benvenuti!
    </p>
    <button
      type="button"
      className="custom__button"
      onClick={() => {
        const menuSection = document.getElementById("menu");
        menuSection.scrollIntoView({ behavior: "smooth" });
      }}
    >
      Explore Menu
    </button>
  </div>
</div>
);

export default Header;
